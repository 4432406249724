import React from 'react';
import '../sass/components/PrivacyPolicyView.scss';

class PrivacyPolicyView extends React.Component {

  render = () => {
    return (
      <div className='privacyPolicyView'>
        <p>Here will be Privacy policy and contact.</p>
      </div>
    )
  }

}

export default PrivacyPolicyView;
