import React from 'react';

import '../../../sass/components/SignInView.scss';

class ActionDescription extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <div className='actionDescription'>
        <h1>{this.props.header}</h1>
        <div className='textInformation'>
          <span>{this.props.description}</span>
          {this.props?.phone ? <span>{this.props.phone}</span> : null}
        </div>
      </div>
    )
  }
}

export default ActionDescription;
