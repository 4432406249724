import React from 'react';

import '../sass/components/TermsView.scss';

class TermsView extends React.Component {

  render = () => {
    return (
      <div className='termsView'>
        Here will be Terms of service.
      </div>
    )
  }

}

export default TermsView;
