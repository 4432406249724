import React from 'react';
import PhoneInput from 'react-phone-input-2';

import { sendRequest } from '../../../helpers/RequestDispatcher';
import Storage from '../../../helpers/Storage';
import {triggerEvent} from '../../../helpers/global';
import TextInput from '../../input/TextInput';
import PrimaryButton from '../../../components/common/Button/Primary/PrimaryButton';
import ActionDescription from './ActionDescription';
import CheckboxInput from '../../input/CheckboxInput';
import { PHONE_COUNTRIES } from './phoneCountries'

import 'react-phone-input-2/lib/style.css'
import '../../../sass/components/SignInView.scss';

const PhoneFieldStyles = {
  containerStyle: {
    width: '100%',
    border: 'none'
  },
  buttonStyle: {
    backgroundColor: 'transparent',
    border: 'none'
  },
  inputStyle: {
    borderRadius: 0,
    border: 'none',
    borderBottom: '1px solid #7F8E9C',
    backgroundColor: 'transparent',
    width: '100%',
  }
}

const ExpiredTime = 120;

class SignInView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '',
      actionState: 'phone',
      error: '',
      phoneValid: false,
      timer: ExpiredTime,
      smsCode: '',
      privacyAndPolicy: false
    }
  }

  countdownInterval = () => {
    if (this.state.actionState === 'smsCode') {
      this.myInterval = setInterval(() => {
        const { timer } = this.state;
        if (timer > 0) {
          this.setState({timer: timer - 1});
        }
        if (timer === 0) {
          clearInterval(this.myInterval);
        }
      }, 1000);
    }
  }

  handlePhone = (value, country, e, formattedValue) => {
    const { countryCode } = country;
    const correspondingCountry = PHONE_COUNTRIES.find((country) => country.iso2 === countryCode.toUpperCase());

    // lengthChecking if no country in PHONE_COUNTRIES list (No Georgia)
    // Note: Temporarily blocked, it is better to add the country to PHONE_COUNTRIES
    //
    // const lengthChecking = format?.length === formattedValue?.length && (value.startsWith(dialCode) || dialCode.startsWith(value));

    if (correspondingCountry.hasOwnProperty('validation') && value) {
      const formattedNumber = '+' + value.replace(/ /g, '');
      const numberIsCorrect = new RegExp(correspondingCountry.validation).test(formattedNumber);
      if (numberIsCorrect) {
        this.setState({
          phoneNumber: formattedValue,
          phoneValid: true,
        });
      } else {
        this.setState({
          phoneNumber: formattedValue,
          phoneValid: false,
        });
      }
    }
  }

  onHandlePrivacyAndPolicy = (value) => {
    this.setState({ privacyAndPolicy: value });
  }

  sendPhone = () => {
    const { phoneNumber, actionState, privacyAndPolicy } = this.state;
    if (!privacyAndPolicy) {
      const text = 'To continue need confirm Terms of Service and Privacy Policy';
      triggerEvent('showSnackbar', [{text: text, type: 'error'}]);
      return;
    }
    sendRequest({
      type: 'POST',
      method: 'auth/registration',
      data: { phone_number: phoneNumber },
      success: (data) => {
        if (actionState === 'phone') this.setState({ actionState: 'smsCode' });
        triggerEvent('showSnackbar', [{text: 'SMS code send', type: 'success'}]);
        this.countdownInterval();
      },
      error: (data) => {
        console.error(data);
        triggerEvent('showSnackbar', [{text: data.errors.sms_code, type: 'error'}]);
      }
    });
  }

  setLogInData = (data) => {
    localStorage.setItem('picture-game-client:token', data.access_token);
    Storage.setData('user', data.user);
  }

  logIn = () => {
    const { phoneNumber, smsCode } = this.state;
    sendRequest({
      type: 'POST',
      method: 'auth/phone_number_confirmation',
      data: {
        phone_number: phoneNumber,
        sms_code: smsCode
      },
      success: (data) => {
        if (data.access_token) {
          this.setLogInData(data);
          this.props.history.push('/');
          triggerEvent('showSnackbar', [{text: 'Success Login', type: 'success'}]);
        }
      },
      error: (data) => {
        console.error(data);
        triggerEvent('showSnackbar', [{text: data.errors.sms_code, type: 'error'}]);
      }
    });
  }

  renderPhoneEnter = () => {
    const { phoneValid, error, privacyAndPolicy } = this.state;
    return (
      <>
        <ActionDescription
          header='Sign in'
          description='We will send you SMS with activation code'
        />
        <div className='fieldDescription'>
          <span>Phone</span>
          {error ? <span className='error'>{error}</span> : null}
        </div>
        <div className='phoneField'>
          <PhoneInput
            country='ee'
            containerStyle={PhoneFieldStyles.containerStyle}
            buttonStyle={PhoneFieldStyles.buttonStyle}
            inputStyle={PhoneFieldStyles.inputStyle}
            value={this.state.phoneNumber}
            onChange={this.handlePhone}
          />
        </div>
        <div className='confirmTermsAndPolicy'>
          <CheckboxInput
            onChange={this.onHandlePrivacyAndPolicy}
            value={privacyAndPolicy}
            small
          />
          <p>
            {'I agree to the '}
            <span onClick={() => this.props.history.push('/terms')}>Terms of Service</span>
            {' and '}
            <span onClick={() => this.props.history.push('/privacy_policy')}>Privacy Policy</span>.
          </p>
        </div>
        <PrimaryButton
          disabled={!phoneValid}
          text='Next'
          onClick={this.sendPhone}
        />
      </>
    )
  }

  renderSMSCodeEnter = () => {
    const { phoneNumber, timer, smsCode } = this.state;
    return (
      <>
        <ActionDescription
          header='Enter code from SMS'
          description='We sent SMS with code to: '
          phone={phoneNumber}
        />
        <div className='fieldDescription'>
          <span>Code</span>
        </div>
        <div className='phoneField'>
          <TextInput
            maxLength={6}
            placeholder='* * * * * *'
            value={smsCode}
            onChange={v => this.setState({ smsCode: v })}
          />
        </div>
        <PrimaryButton text='Enter' onClick={this.logIn} />
        {timer === 0
          ? <div
            onClick={() => this.setState({ timer: ExpiredTime }, this.sendPhone)}
            className='resendSMS'>Resend SMS
          </div>
          : <div className='resendSMS'>{`We can resend SMS after ${timer} seconds`}</div>
        }
      </>
    )
  }

  render = () => {
    const { actionState } = this.state;
    return (
      <div className='signIn'>
        {actionState === 'phone' ? this.renderPhoneEnter() : this.renderSMSCodeEnter()}
      </div>
    )
  }
}

export default SignInView;
