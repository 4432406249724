import React from 'react';
import classnames from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';

import { eventOn, eventOff } from '../../helpers/global';

import '../../sass/components/common/ConfirmationPopup.scss';

class ConfirmationPopup extends React.Component {
  constructor(props) {
    super();
    this.state = {
      hidden: !props.show,
      show: false,
      title: null,
      body: null,
      content: null,
      confirmText: null,
      cancelText: null,
      callback: null,
      overlayBlock: false,
    };
  }

  componentDidMount = () => {
    if (this.props.global) {
      eventOn('showConfirmation', this.showConfirmation);
    }
  }

  componentWillUnmount = () => {
    if (this.props.global) {
      eventOff('showConfirmation', this.showConfirmation);
    }
  }

  showConfirmation = (e) => {
    const { title, body, content, confirmText, cancelText, callback, overlayBlock } = e.detail[0];
    this.setState({
      hidden: false,
      show: true,
      title,
      body,
      content,
      confirmText,
      cancelText,
      callback,
      overlayBlock,
    });
  }

  onOverlayClick = (e) => {
    if (!this.state.overlayBlock && e.target === e.currentTarget) {
      this.onAction(false);
    }
  }

  onAction = (result) => {
    if (this.props.global) {
      if (this.state.callback) {
        this.state.callback(result);
      }
      this.setState({show: false});
    } else {
      if (this.props.callback) {
        this.props.callback(result);
      }
    }
  }

  render = () => {
    const { show, title, body, content, confirmText, cancelText } = this.props.global ? this.state : this.props;
    return (
      <div
        className={classnames({
          'confirmationPopup': true,
          'hidden': this.state.hidden,
        })}
      >
        <CSSTransition
          in={show}
          timeout={350}
          classNames='fade'
          onEnter={() => this.setState({hidden: false})}
          onExited={() => this.setState({hidden: true})}
        >
          <div
            onClick={this.onOverlayClick}
            className='overlay'
          >
            <div className='popupWindow'>
              <div className='confirmTitle'>{title}</div>
              {body ?
                <div className='confirmBody' dangerouslySetInnerHTML={{__html: body}}/>
              : null}
              {content && <div className='confirmContent'>{content}</div>}
              <div className='popupControls'>
                {confirmText === false ? null :
                  <div
                    onClick={() => this.onAction(true)}
                    className='popupControl confirm'
                  >{confirmText || 'Confirm'}</div>
                }
                {cancelText === false ? null :
                  <div
                    onClick={() => this.onAction(false)}
                    className='popupControl cancel transparent'
                  >{cancelText || 'Cancel'}</div>
                }
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default ConfirmationPopup;
