import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import EventListView from './EventListView';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class HomeView extends React.Component {

  constructor(props) {
    super(props);
  }

  render = () => {
    return <EventListView />
  }
}

export default connect(mapStoreToProps)(withRouter(HomeView));
