import React from 'react';
import classnames from 'classnames';

import '../../sass/components/input/ImageInput.scss';

export default class ImageInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      preview: props.value,
    };
    this.input = null;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.value !== this.props.value) {
      if (typeof this.props.value === 'string') {
        this.setState({preview: this.props.value});
      }
    }
  }

  handleChange = (file) => {
    this.setState({preview: file ? URL.createObjectURL(file) : null});
    this.props.onChange(file);
  }

  render = () => {
    return (
      <div className='imageInput'>
        <input
          type='file'
          accept='image/png,image/x-png,image/gif,image/jpeg'
          ref={input => this.input = input}
          onChange={e => this.handleChange(e.target.files[0])}
        />
        <div
          className={classnames('imagePreview', {
            'round': this.props.round,
          })}
          style={{backgroundImage: `url(${this.state.preview})`}}
          onClick={() => this.input.click()}
        >
          {this.state.preview ? null : <div className='uploadContent'>
            <div className='uploadIcon'/>
            <div className='uploadPlacehodler'>{this.props.placehodler || 'Upload image'}</div>
            <div className='uploadRecommendations'>{this.props.recommendations}</div>
          </div>}
        </div>
      </div>
    )
  }
}
