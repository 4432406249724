import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';

import { triggerEvent, eventOn } from './helpers/global.js';
import { sendRequest } from './helpers/RequestDispatcher.js';
import Storage from './helpers/Storage.js';

import HeaderView from './components/HeaderView';
import FooterView from './components/FooterView';

import ConfirmationPopup from './components/common/ConfirmationPopup';
import ContentPopup from './components/common/ContentPopup';
import Snackbar from './components/common/Snackbar';
import LoadIndicator from './components/common/LoadIndicator';

import HomeView from './components/HomeView';
import RestorePasswordView from './components/auth/RestorePasswordView';
import ConfirmationView from './components/auth/ConfirmationView';
import SignInView from './components/auth/SignIn/SignInView';
import UserView from './components/UserView';
import EditUserView from './components/EditUserView';
import EventListView from './components/EventListView';
import TermsView from './components/TermsView';
import PrivacyPolicyView from './components/PrivacyPolicyView';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

const UserRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    if (!rest.user) return <Redirect to='/login'/>
    return <Component {...props}/>
  }}/>
)

// const LoginRoute = ({ component: Component, ...rest }) => (
//   <Route {...rest} render={(props) => (
//     !rest.user ? <Component {...props}/> : <Redirect to='/home'/>
//   )}/>
// )

const AnyRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    <Component {...props}/>
  )}/>
)

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoadIndicator: false,
    };
    this.loadCount = 0;

    eventOn('addLoad', this.addLoad);
    eventOn('removeLoad', this.removeLoad);
    eventOn('unauthorized', this.onUnauthorized);
    eventOn('logout', () => this.onLogout(false));

    eventOn('resize', (e) => {
      Storage.setSetup('isMobile', window.innerWidth <= 768);
    }, window);
    Storage.setSetup('isMobile', window.innerWidth <= 768);
  }

  componentDidUpdate = (prevProps, prevState) => {
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      eventOn('resize', (e) => {
        Storage.setSetup('isMobile', window.innerWidth <= 768);
      }, window);

      Storage.setSetup('isMobile', window.innerWidth <= 768);
    }

    if (this.props.user) {
      sendRequest({
        method: 'me/',
        type: 'GET',
        success: (data) => {
          Storage.setData('user', data);
        },
        error: (data) => {
        }
      });
    }
  }

  addLoad = () => {
    this.loadCount++;
    if (this.state.showLoadIndicator !== (this.loadCount > 0)) {
      this.setState({showLoadIndicator: this.loadCount > 0});
    }
  }

  removeLoad = () => {
    this.loadCount--;
    if (this.state.showLoadIndicator !== (this.loadCount > 0)) {
      this.setState({showLoadIndicator: this.loadCount > 0});
    }
  }

  onLogout = (unauthorized) => {
    if (!unauthorized) {
      sendRequest({
        type: 'POST',
        method: 'me/logout',
      });
    }
    Storage.setData('user', null);
    localStorage.removeItem('picture-game-client:token');
  }

  onUnauthorized = () => {
    triggerEvent('showSnackbar', [{text: 'Your access token has been expired. Please re-login.', type: 'error'}]);
    this.onLogout(true);
  }

  render = () => {
    const { user, location } = this.props;
    return (
      <div>
        <div className='appContent'>
          <HeaderView />
          <div className='mainContent'>
            <Switch>
              <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)} />
              <AnyRoute user={user} path='/reset_password/:token' component={RestorePasswordView} />
              <AnyRoute user={user} path='/sign_in' component={SignInView} />
              <AnyRoute user={user} path='/confirmation/:token' component={ConfirmationView} />
              <UserRoute user={user} path='/users/:user_id?/edit' component={EditUserView} />
              <AnyRoute user={user} path='/users/:id?' component={UserView} />
              <AnyRoute user={user} path='/events/:id?' component={EventListView} />
              <AnyRoute user={user} path='/terms' component={TermsView} />
              <AnyRoute user={user} path='/privacy_policy' component={PrivacyPolicyView} />
              <AnyRoute user={user} path='/' component={HomeView} />
            </Switch>
          </div>
          <FooterView />
        </div>

        <LoadIndicator show={this.state.showLoadIndicator}/>
        <ConfirmationPopup global />
        <ContentPopup global />
        <Snackbar />
        <CookieConsent
          location='bottom'
          buttonText='Agree and continue'
          declineButtonText='Decline'
          cookieName='pictureGameCookie'
          style={{ background: '#2B373B' }}
          buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
          expires={365}
          enableDeclineButton={true}
        >
          We use cookies and similar technologies to provide certain features,
          enhance the user experience and deliver content that is relevant to your
          interests. Depending on their purpose, analysis and marketing cookies
          may be used in addition to technically necessary cookies. By clicking on
          &quot;Agree and continue&quot;, you declare your consent to the use of
          the aforementioned cookies.
        </CookieConsent>
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(App));
