import React from 'react';

export default ({
  width = '25px',
  height = '25px',
  color = '#000'
}) =>
  <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 6.58749L18.4125 0.999992C18.2277 0.813292 18.0077 0.665082 17.7652 0.563934C17.5228 0.462785 17.2627 0.410706 17 0.410706C16.7373 0.410706 16.4772 0.462785 16.2348 0.563934C15.9923 0.665082 15.7723 0.813292 15.5875 0.999992L0.587501 16C0.401123 16.185 0.253243 16.405 0.152405 16.6475C0.0515658 16.8899 -0.000233085 17.1499 7.88436e-07 17.4125V23C7.88436e-07 23.5304 0.210714 24.0391 0.585787 24.4142C0.96086 24.7893 1.46957 25 2 25H7.5875C7.85008 25.0002 8.1101 24.9484 8.35254 24.8476C8.59498 24.7467 8.81504 24.5989 9 24.4125L24 9.41249C24.371 9.03597 24.579 8.52859 24.579 7.99999C24.579 7.47139 24.371 6.96402 24 6.58749ZM7.5875 23H2V17.4125L13 6.41249L18.5875 12L7.5875 23ZM20 10.5875L14.4125 4.99999L17 2.41249L22.5875 7.99999L20 10.5875Z"
          fill={color}
    />
  </svg>
;
