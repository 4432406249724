import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { triggerEvent } from '../helpers/global';
import { sendRequest } from '../helpers/RequestDispatcher';
import ImageInput from './input/ImageInput';
import {
  Trash,
  Edit
} from './icons'

import '../sass/components/MessageView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class MessageView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: props.message || null,
      file: null,
      fileUrl: '',
      event: props.event
    };
  }

  handleFileChange = (file) => {
    this.setState({file: file, fileUrl: URL.createObjectURL(file)});
  }

  onUpdate = () => {
    const { fileUrl, file, message } = this.state;

    if (!fileUrl) return;

    const formData = new FormData();

    formData.append('image', file);

    sendRequest({
      type: 'PUT',
      method: `messages/${message.id}`,
      formData,
      success: (data) => {
        this.setState({
          file: null,
          fileUrl: '',
          message: data
        }, () => {
          this.props.fetchEvent();
          triggerEvent('showSnackbar', [{text: 'Photo updated', type: 'success'}]);
        })
      },
      error: (data) => {
        if (data.errors && data.errors.image) {
          triggerEvent('showSnackbar', [{text: data.errors.image, type: 'error'}]);
        }
      }
    });
  }

  onDestroy = () => {
    const { message } = this.state;

    sendRequest({
      type: 'DELETE',
      method: `messages/${message.id}`,
      success: (data) => {
        if (data && data === 200) {
          this.props.fetchEvent();
          triggerEvent('showSnackbar', [{text: 'Photo deleted', type: 'success'}]);
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  onEdit = () => {
    const { message, fileUrl } = this.state;

    triggerEvent('showContentPopup', [{
      cancelText: 'Close',
      confirmText: 'Update',
      callback: this.onUpdate,
      content: <div className='selectImage'>
        <div className='popupControls'>
          <ImageInput value={fileUrl || message.image_url} onChange={(f) => this.handleFileChange(f)}/>
        </div>
      </div>
    }])
  }

  onDelete = () => {
    triggerEvent('showContentPopup', [{
      cancelText: 'Close',
      confirmText: 'Delete',
      title: 'Are sure?',
      callback: this.onDestroy,
    }])
  }

  render = () => {
    const { message, event } = this.state;
    const { user } = this.props;

    return message.image_url
      ? <div
          className='messageView'
          style={{backgroundImage: `url(${message.image_url})`}}
          onClick={(event) => {
            if (event.target === event.currentTarget) {
              triggerEvent('showContentPopup', [{
                content: <div
                  className='modalPicture'
                  style={{backgroundImage: `url(${message.image_url})`}}
                  onClick={() => window.open(message.image_url, '_blank')}
                />
              }])
            }
          }}
        >
        {
          (user?.id === message.user.id && event.active)
          ? <>
              <div className='action' onClick={this.onEdit}>
                <Edit width='17px' height='17px' />
              </div>
              <div className='action' onClick={this.onDelete}>
                <Trash width='19px' height='20px' />
              </div>
            </>
          : null
        }
        {event.winner?.message?.id === message.id ? <div className='winnerBadge smallPadding'>Winner</div> : null}
        </div>
      : null
  }
}

export default connect(mapStoreToProps)(withRouter(MessageView));

