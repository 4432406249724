import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { initCache } from './helpers/RequestDispatcher.js';
import Storage from './helpers/Storage.js';

import App from './App';

import './sass/fonts.scss';
import './sass/base.scss';

require('./css/flexboxgrid.min.css');

const init = async () => {
  dayjs.extend(relativeTime);
  try {
    await Promise.all([
      initCache(),
      Storage.loadData('user'),
    ])
  } catch (ex) {}
}

init().then(() => {
  ReactDOM.render(
    <Provider store={Storage.getStore()}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </Provider>,
    document.getElementById('content')
  )
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

