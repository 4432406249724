import React from 'react';
import dayjs from 'dayjs';
import { withRouter } from 'react-router-dom';
import CountdownTimer from './common/CountdownTimer';

import '../sass/components/EventCardView.scss';

class EventCardView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      event: props.event || null,
    };
  }

  renderSoonEvent = (event) => {
    if (!event.soon) return null;

    return (
      <div className='upcomingEvent'>
        <div className='startIn'>
          Starts in
        </div>
        <CountdownTimer withoutStyle withoutTitle date={event.scheduled_at} />
      </div>
    )
  }

  render = () => {
    const { event } = this.state;

    return (
      <div className='eventCardView' onClick={() => this.props.history.push(`/events/${event.id}`)}>
        <div className='eventDateTime'>
          <div className='date'>
            {dayjs.unix(event.scheduled_at).format('D MMMM YYYY')}
          </div>
          <div className='time'>
            {dayjs.unix(event.scheduled_at).format('HH:mm')}
          </div>
        </div>
        {this.renderSoonEvent(event)}
        { event.thumb_url &&
          <div className='wrapperPictures'>
            <div key={event.id} className='picture' style={{backgroundImage: `url(${event.thumb_url})`}} />
          </div>
        }
      </div>
    )
  }

}

export default withRouter(EventCardView);
