import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { sendRequest } from '../helpers/RequestDispatcher';
import MessageListView from './MessageListView';
import WrapperEvent from './Event';

import '../sass/components/EventView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class EventView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      event: null
    };
  }

  componentDidMount = () => {
    this.fetchEvent();
  }

  fetchEvent = () => {
    sendRequest({
      type: 'GET',
      method: `events/${this.props.event_id}`,
      success: (data) => {
        if (data) {
          this.setState({ event: data });
        }
      },
      error: (data) => {
      }
    });
  }

  render = () => {
    const { event } = this.state;
    if (!event) return null;

    return (
      <div className='eventView'>
        <WrapperEvent event={event} fetchEvent={this.fetchEvent} />
        <MessageListView event={event} messages={event.messages} fetchEvent={this.fetchEvent} />
      </div>
    )
  }

}

export default connect(mapStoreToProps)(withRouter(EventView));
