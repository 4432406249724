import React from 'react';
import { withRouter } from 'react-router-dom';
import { sendRequest } from '../helpers/RequestDispatcher';

import EventView from './EventView';
import EventCardView from './EventCardView';

import '../sass/components/EventListView.scss';

class EventListView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pastEvents: [],
      activeEvents: [],
      soonEvents: []
    };
  }

  componentDidMount = () => {
    sendRequest({
      type: 'GET',
      method: 'events',
      data: { type: 'active' },
      success: (data) => {
        if (data) {
          this.setState({activeEvents: data});
        }
      },
      error: (data) => {
      }
    });
    sendRequest({
      type: 'GET',
      method: 'events',
      data: { type: 'past' },
      success: (data) => {
        if (data) {
          this.setState({pastEvents: data});
        }
      },
      error: (data) => {
      }
    });
    sendRequest({
      type: 'GET',
      method: 'events',
      data: { type: 'soon' },
      success: (data) => {
        if (data) {
          this.setState({soonEvents: data});
        }
      },
      error: (data) => {
      }
    });
  }

  renderEvents = (events, title, description) => {
    return (
      <div>
        <div className='eventsType'>
          { title ? <div className='title'>{title}</div> : null }
          { description ? <div className='description'>{description}</div> : null }
        </div>
        {events.map(event =>
          <div key={event.id} className='col-sm-12'>
            <EventCardView event={event} />
          </div>
        )}
      </div>
    )
  }

  render = () => {
    if (this.props.match.params.id) return <EventView event_id={this.props.match.params.id} />
    const { pastEvents, activeEvents, soonEvents } = this.state;

    return (
      <div className='eventListView'>
        <div className='column'>
          { soonEvents?.length ? this.renderEvents(soonEvents, 'Upcoming events') : null }
          { activeEvents?.length ? this.renderEvents(activeEvents, 'Active events', 'Latest photos') : null }
          { pastEvents?.length ? this.renderEvents(pastEvents, 'Previous events') : null }
        </div>
      </div>
    )
  }
}

export default withRouter(EventListView);
