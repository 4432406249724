import * as React from 'react';
import styles from '../Button.module.scss';

const PrimaryButton = ({
  text,
  size,
  onClick,
  height= 42,
  disabled = false,
}) => {
  return <button
    style={{height: `${height}px`}}
    type='button'
    className={styles.primaryButton}
    onClick={onClick}
    disabled={disabled}
  >
    {text}
  </button>
};

export default PrimaryButton;
