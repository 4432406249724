import React from 'react';
import '../../sass/components/input/ButtonIcon.scss';

const ButtonIcon = (props) => {
  return (
    <div
      onClick={props.action}
      className='buttonIcon'
    >
      {props.children}
    </div>
  )
}

export default ButtonIcon;
