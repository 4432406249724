import React from 'react';
import classnames from 'classnames';

import '../../sass/components/common/Avatar.scss';

class Avatar extends React.PureComponent {

  render = () => {
    const { image, username, className, small, big, tiny } = this.props;
    
    return (
      <div
        className={classnames({
          'profileImage': true,
          'noAvatar': !image,
          'small': small,
          'big': big,
          'tiny': tiny,
        }, className)}
        onClick={() => {
          if (this.props.onClick) {
            this.props.onClick()
          }
        }}
      >
        {image &&
        <picture>
          <img src={image} alt={username} />
        </picture>
        }
      </div>
    )
  }
}

export default Avatar;
