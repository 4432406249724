import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Storage from '../helpers/Storage';
import { triggerEvent, eventOn } from '../helpers/global.js';
import { sendRequest } from '../helpers/RequestDispatcher.js';

import ButtonIcon from './input/ButtonIcon';
import logo from '../images/logo.png'

import {
  Trash,
  SignOut,
  SignIn
} from './icons'

import '../sass/components/HeaderView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class HeaderView extends React.Component {
  showLogout = () => {
    triggerEvent('showConfirmation', [{
      title: 'Are you sure you want to log out?',
      overlayBlock: false,
      callback: this.logoutAction
    }]);
  }

  showDeleteUser = () => {
    triggerEvent('showConfirmation', [{
      title: 'Are you sure you want to delete account?',
      overlayBlock: false,
      callback: this.deleteUserAction
    }]);
  }

  deleteUserAction = (action) => {
    const { user } = this.props;
    if (action) {
      sendRequest({
        type: 'DELETE',
        method: `auth/${user.id}`,
        success: (data) => {
          Storage.setData('user', null);
          localStorage.setItem('picture-game-client:token', null);
          triggerEvent('showSnackbar', [{text: 'Account removed successfully', type: 'success'}]);
        },
        error: (data) => {
          console.error(data);
        }
      });
    }
  }

  logoutAction = (action) => {
    if (action) {
      sendRequest({
        type: 'POST',
        method: 'me/logout',
        success: (data) => {
          Storage.setData('user', null);
          localStorage.setItem('picture-game-client:token', null);
          triggerEvent('showSnackbar', [{text: 'Success logout', type: 'success'}]);
        }
      });
    }
  }

  render = () => {
    const { user } = this.props;
    return (
      <div className='headerView'>
        <div className='headerContent'>
          <div
            className='headerLogo'
            onClick={() => this.props.history.push('/')}
          >
            <img src={logo} alt='logo' />
          </div>
          <div className='authGroup'>
            {user
              ? <>
                <ButtonIcon action={this.showDeleteUser}>
                  <Trash />
                </ButtonIcon>
                <ButtonIcon action={this.showLogout}>
                  <SignOut />
                </ButtonIcon>
              </>
              : <ButtonIcon action={() => this.props.history.push('/sign_in')}>
                <SignIn />
              </ButtonIcon>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(HeaderView));
