import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import Storage from '../helpers/Storage';
import { triggerEvent } from '../helpers/global.js';
import { sendRequest } from '../helpers/RequestDispatcher.js';

import TextInput from './input/TextInput';
import ImageInput from './input/ImageInput';

import '../sass/components/EditUserView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

const PAIRED_FIELDS = {
  first_name: {
    type: 'text',
    title: 'First Name',
  },
  last_name: {
    type: 'text',
    title: 'Last Name',
  },
  phone_number: {
    type: 'text',
    title: 'Phone number',
  },
  phone_number_active: {
    type: 'button',
    title: 'Confirmed',
    action: 'phoneConfirmation',
    showCondition: (object) => !object.phone_number || !object.phone_number_active
  },
};

const INDIVIDUAL_FIELDS = {
}

class EditUserView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
    };
    this.smsCodeRef = null;
  }

  componentDidMount = () => {
    this.getData();
  }

  getData = () => {
    sendRequest({
      type: 'GET',
      method: 'me',
      success: (data) => {
        if (data) {
          this.setState({data});
        }
      },
      error: (data) => {
      }
    });
  }

  sendSMSCode = () => {
    sendRequest({
      type: 'GET',
      method: 'auth/activate_phone',
      success: (data) => {
        triggerEvent('showSnackbar', [{text: 'SMS Code Successfully Send', type: 'success'}]);
      },
      error: (data) => {
        if (data.errors && data.errors?.sms_code) {
          triggerEvent('showSnackbar', [{text: data.errors?.sms_code, type: 'error'}]);
        }
      }
    });
  }

  confirmPhone = () => {
    sendRequest({
      type: 'POST',
      method: 'auth/confirm_phone_activation',
      data: { sms_code: this.smsCodeRef.value},
      success: (data) => {
        triggerEvent('showSnackbar', [{text: 'Phone Successfully Confirmed', type: 'success'}]);
        this.setState({data: {
          ...this.state.data,
          phone_number_active: true
        }}, () => this.smsCodeRef.value = null)
      },
      error: (data) => {
        if (data.errors && data.errors?.sms_code) {
          this.smsCodeRef.value = null;
          triggerEvent('showSnackbar', [{text: data.errors?.sms_code, type: 'error'}]);
        }
      }
    });
  }

  onSave = () => {
    let formData = new FormData();
    let errors = {};

    const { first_name, last_name } = this.state.data;
    if (!first_name) {
      errors.first_name = 'Please enter First Name';
    }
    if (!last_name) {
      errors.last_name = 'Please enter Last Name';
    }
    this.setState({errors});
    if (Object.keys(errors).length > 0) {
      return;
    }
    Object.keys(PAIRED_FIELDS).forEach(key => {
      const value = this.state.data[key];
      if (value) {
        formData.append(key, value);
      }
    });
    const image = this.state.data && this.state.data.image;
    if (image && typeof image !== 'string') {
      formData.append('image', image);
    }
    Object.keys(INDIVIDUAL_FIELDS).forEach(key => {
      const value = this.state.data[key];
      if (!value) {
        return;
      }
      if (['image', 'file'].indexOf(INDIVIDUAL_FIELDS[key].type) > -1) {
        if (typeof value !== 'string') {
          formData.append(key, value);
        }
      } else {
        formData.append(key, value);
      }
    });
    sendRequest({
      method: 'me',
      type: 'PUT',
      formData,
      success: (data) => {
        if (data) {
          this.setState({data});
          Storage.setData('user', data);
          triggerEvent('showSnackbar', [{text: 'Profile successfully updated', type: 'success'}]);
        }
      },
      error: (data) => {
        if (data.errors && data.errors.name) {
          triggerEvent('showSnackbar', [{text: data.errors.name, type: 'error'}]);
        } else if (data.errors) {
          triggerEvent('showSnackbar', [{text: data.errors, type: 'error'}]);
        }
      }
    });
  }

  renderInput = (properties) => {
    const value = this.state.data[properties.key];
    if (properties.showCondition && !properties.showCondition(this.state.data)) return null;

    const commonProps = {
      ...properties,
      value,
      error: this.state.errors[properties.key],
      onChange: val => {
        this.setState({
          data: {
            ...this.state.data,
            [properties.key]: val,
          }
        })
      },
    }
    return (
      <div className='inputBlock' key={properties.key}>
        {properties.title ? <label>{properties.title}</label> : null}
        {['text', 'textarea'].includes(properties.type) ? <TextInput {...commonProps}/> : null }
        {properties.type === 'image' ? <ImageInput {...commonProps}/> : null }
        {properties.type === 'button' ? this.renderButton(commonProps) : null }
      </div>
    )
  }

  renderUserForm = () => {
    return (
      <>
        <div className='pairedInputBlock'>
          {Object.keys(PAIRED_FIELDS).map(key => this.renderInput({key, ...PAIRED_FIELDS[key]}))}
        </div>
        {Object.keys(INDIVIDUAL_FIELDS).map(key => this.renderInput({key, ...INDIVIDUAL_FIELDS[key]}))}
      </>
    );
  }

  renderUserImage = () => {
    return this.renderInput({
      key: 'image',
      type: 'image',
      title: 'Profile Photo',
      placeholder: 'Upload Photo',
      round: true,
    });
  }

  renderPhoneNumberConformation = () => {
    return (
      <div className='phoneNumberConformation'>
        <TextInput
          inputRef={ref => { this.smsCodeRef = ref }}
          placeholder='SMS Code'
          maxLength={4}
        />
        <button onClick={this.sendSMSCode}>
          Send SMS Code
        </button>
      </div>
    )
  }

  phoneConfirmation = () => {
    triggerEvent('showContentPopup', [{
      title: 'Confirm Phone Number',
      content: this.renderPhoneNumberConformation(),
      confirmText: 'Confirm',
      cancelText: 'Close',
      callback: this.confirmPhone,
      overlayBlock: true,
    }]);
  }

  renderButton = (properties) => {
    let action = null;
    if (properties.action === 'phoneConfirmation') {
      action = this.phoneConfirmation;
    }
    return (
      <div
        onClick={action}
        className={classnames('inputButton', {
          fulfilled: properties.value,
        })}>
        {(!properties.value && properties.value !== undefined) && 'Not'} {properties.title}
      </div>
    )
  }

  render = () => {
    return (
      <div className='editUserView'>
        <div className='userContent'>
          <div className='userForm'>{this.renderUserForm()}</div>
          <div className='userImage'>{this.renderUserImage()}</div>
        </div>
        <div className='buttonBlock'>
          <button onClick={this.onSave}>
            Save
          </button>
        </div>
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(EditUserView));
