import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import '../sass/components/FooterView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class FooterView extends React.Component {

  render = () => {
    return (
      <div className='footerView'>
        <div className='content'>
          <div className='block' onClick={() => this.props.history.push('/terms')}>Terms of service</div>
          <div className='block' onClick={() => this.props.history.push('/privacy_policy')}>Privacy policy and contact</div>
        </div>
      </div>
    )
  }

}

export default connect(mapStoreToProps)(withRouter(FooterView));
