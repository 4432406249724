import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Avatar from './common/Avatar';

import { sendRequest } from '../helpers/RequestDispatcher.js';

import '../sass/components/UserView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class UserView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  componentDidMount = () => {
    this.initData(this.props.match.params.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  initData = (id) => {
    sendRequest({
      method: `users/${id}`,
      type: 'GET',
      success: (data) => {
        this.setState({data: data});
      },
      error: (data) => {
      }
    });
  }

  isOwnUser = () => {
    return this.props.user && this.props.match.params.id === this.props.user.id.toString();
  }

  handleEditUserClick = () => {
    this.props.history.push(`/users/${this.props.user.id}/edit`);
  }

  renderProfile = () => {
    const user = this.state.data;
    const isOwnProfile = this.isOwnUser();
    return (
      <div className='container-fluid userHeader'>
        <div className='row'>
          <div className='col-sm-3 col-xs-12'>
            <Avatar
              image={user.image}
              username={user.name}
              className='userImage'
              big
            />
          </div>
          <div className='col-sm-6 col-xs-12'>
            <div className='userNameLocation'>
              <div className='userName title'>
                {user.name}
              </div>
            </div>
          </div>
          {isOwnProfile
            ? <div className='col-sm-3 col-xs-12'>
                <div className='editUserBtn' onClick={this.handleEditUserClick}>Edit Profile</div>
              </div>
            : null
          }
        </div>
      </div>
    )
  }

  render = () => {
    return (
      <div className='userView'>
        { this.renderProfile() }
      </div>
    )
  }

}

export default connect(mapStoreToProps)(withRouter(UserView));
