import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import { triggerEvent } from '../../helpers/global';

import '../../sass/components/EventView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class Finished extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      event: props.event || null,
    };
  }

  render = () => {
    const { event } = this.state;
    if (!event) return null;

    return (
      <div className='eventDescription'>
        <div className='time'>
          {dayjs.unix(event.finished_at).format('HH:MM D MMMM YYYY')}
        </div>
        <div className='taskTitle'>
          Task
        </div>
        <div className='task'>
          {event.task}
        </div>
        <div className='taskTitle'>
          Example
        </div>
        <div
          className='image'
          style={{backgroundImage: `url(${event.image})`}}
          onClick={() => triggerEvent('showContentPopup', [{
            content: <div
              className='modalImage'
              style={{backgroundImage: `url(${event.image})`}}
              onClick={() => window.open(event.image, '_blank')}
            />
          }])}
        >
        </div>
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(Finished));
