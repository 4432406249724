import React from 'react';
import dayjs from 'dayjs';
import '../../sass/components/common/Timer.scss';

class Timer extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      scheduled_at: props.scheduled_at || null,
      finished_at: props.finished_at || null,
      displayTime: null,
      finished: dayjs(new Date).unix() >= props.finished_at
    };
  }

  componentDidMount = () => {
    this.upInterval();
  }

  renderComplete = () => {
    const { event } = this.props;
      if (event) {
        document.getElementById('refreshLink').click();
      }
    return null;
  }

  hms = (seconds) => {
    return [3600, 60]
      .reduceRight(
        (p, b) => r => [Math.floor(r / b)].concat(p(r % b)),
        r => [r]
      )(seconds)
      .map(a => a.toString().padStart(2, '0'))
      .join(':');
  }

  upInterval = () => {
    this.myInterval = setInterval(() => {
      const timeNow = dayjs(new Date).unix();
      const { scheduled_at, finished_at } = this.state;
      if (timeNow <= finished_at && timeNow >= scheduled_at) {
        const diffInInt = dayjs(timeNow).diff(scheduled_at);
        this.setState({ displayTime: this.hms(diffInInt) });
      }
      if (dayjs(new Date).unix() >= finished_at) {
        clearInterval(this.myInterval);
        this.setState({ finished: true });
      }
    }, 1000);
  }

  renderTimer = () => {
    if (this.state.finished) {
      return this.renderComplete();
    } else {
      return (
        <>
          <div className='time'>
            {this.state.displayTime}
          </div>
        </>
      )
    }
  }

  render = () => {
    const { event } = this.props;
    return (
      <div className='upTimer'>
        {event ? <a id='refreshLink' href={`/events/${event.id}`} /> : null}
        {this.renderTimer()}
      </div>
    )
  }
}

export default Timer;
