import React from 'react';
import { withRouter } from 'react-router-dom';

import { triggerEvent } from '../../helpers/global.js';
import { sendRequest } from '../../helpers/RequestDispatcher.js';

import '../../sass/components/ConfirmationView.scss';

class ConfirmationView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      confirmed: false,
      errors: {},
    }
  };

  componentDidMount = () => {
    sendRequest({
      type: 'POST',
      method: 'auth/confirmation',
      data: {
        confirmation_token: this.props.match.params.token,
      },
      success: (data) => {
        if (data) {
          triggerEvent('showSnackbar', [{text: 'Account confirmed', type: 'success'}]);
          this.setState({confirmed: true, loaded: true});
        }
      },
      error: (data) => {
        if (data.errors) {
          if (data.errors.confirmation) {
            triggerEvent('showSnackbar', [{text: data.errors.confirmation, type: 'error'}]);
          }
          this.setState({errors: data.errors, loaded: true});
        }
      }
    })
  }

  render = () => {
    const { confirmed, errors, loaded } = this.state;
    if (!loaded) return null;

    return (
      <div className='confirmationView'>
        {confirmed
          ? <div className='confirmationDescription'>Your account is successful confirmed!</div>
          : <div className='confirmationDescription'>
              {`Confirmation ${errors.confirmation}`}
            </div>
        }
        <div
          className='homePageLink'
          onClick={() => this.props.history.push('/')}
        >
          Home page
        </div>
      </div>
    )
  }

}

export default withRouter(ConfirmationView);
