import React from 'react';

export default ({
  width = '24px',
  height = '24px',
  color = '#00040E'
}) =>
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.7125 12.7125L10.4625 17.9625C10.2705 18.146 10.0155 18.2489 9.75 18.25C9.55171 18.2497 9.35799 18.1904 9.19347 18.0797C9.02895 17.969 8.90104 17.8119 8.82602 17.6284C8.75099 17.4448 8.73222 17.2431 8.77211 17.0489C8.81199 16.8546 8.90872 16.6766 9.05 16.5375L12.5875 13H1C0.734784 13 0.48043 12.8946 0.292893 12.7071C0.105357 12.5196 0 12.2652 0 12C0 11.7348 0.105357 11.4804 0.292893 11.2929C0.48043 11.1054 0.734784 11 1 11H12.5875L9.05 7.4625C8.86103 7.27519 8.75421 7.02049 8.75304 6.75442C8.75187 6.48835 8.85644 6.23272 9.04375 6.04375C9.23106 5.85478 9.48576 5.74796 9.75183 5.74679C10.0179 5.74562 10.2735 5.85019 10.4625 6.0375L15.7125 11.2875C15.9003 11.4771 16.0056 11.7332 16.0056 12C16.0056 12.2668 15.9003 12.5229 15.7125 12.7125V12.7125ZM22 0H15C14.7348 0 14.4804 0.105357 14.2929 0.292893C14.1054 0.48043 14 0.734784 14 1C14 1.26522 14.1054 1.51957 14.2929 1.70711C14.4804 1.89464 14.7348 2 15 2H22V22H15C14.7348 22 14.4804 22.1054 14.2929 22.2929C14.1054 22.4804 14 22.7348 14 23C14 23.2652 14.1054 23.5196 14.2929 23.7071C14.4804 23.8946 14.7348 24 15 24H22C22.5304 24 23.0391 23.7893 23.4142 23.4142C23.7893 23.0391 24 22.5304 24 22V2C24 1.46957 23.7893 0.960859 23.4142 0.585786C23.0391 0.210714 22.5304 0 22 0V0Z" fill={color} />
  </svg>
;
