import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import MessageView from './MessageView';

import '../sass/components/MessageListView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class MessageListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: this.props.messages || [],
      file: null,
      fileUrl: '',
      event: props.event || null
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.messages?.length !== this.props.messages?.length) {
      this.setState({ messages: this.props.messages });
    }
  }

  render = () => {
    const { messages, event } = this.state;

    return (
      <div className='messageListView'>
        <div className='row'>
          {messages?.map(message =>
            <div key={message.id} className={`col-xs-4 messageBlock`}>
              <MessageView event={event} message={message} fetchEvent={this.props.fetchEvent} />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(MessageListView));
