import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { sendRequest } from '../../helpers/RequestDispatcher';
import { WaitingEvent } from '../icons';
import CountdownTimer from '../common/CountdownTimer';
import SecondaryButton from '../common/Button/Secondary/SecondaryButton';

import '../../sass/components/EventView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class Next extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      event: props.event || null,
      file: null,
      fileUrl: '',
      isVisited: false
    };
  }

  componentDidMount() {
    this.visitorsCounter();
  }

  visitorsCounter = () => {
    const { event, isVisited } = this.state;
    if (isVisited || !event) return null;

    sendRequest({
      type: 'POST',
      method: 'events/visitors_counter',
      data: { event_id: event.id },
      success: (data) => {
        if (data) this.setState({ event: data, isVisited: true });
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  render = () => {
    const { event } = this.state;
    const { user } = this.props;
    if (!event) return null;

    return (
      <div className='eventDescription'>
        <div className='taskTitle'>
          {`Event views: ${event.visitors_counter}`}
        </div>
        <div className='waitingImage'>
          <WaitingEvent />
        </div>
        <CountdownTimer event={event} withoutTitle date={event.scheduled_at} />
        {user ? null
          :  <SecondaryButton
            text='Log in to participate'
            onClick={() => this.props.history.push('/sign_in')}
          />
        }
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(Next));
