import * as React from 'react';
import styles from '../Button.module.scss';

const SecondaryButton = ({
  text,
  size,
  onClick,
  height= 54,
  disabled = false,
}) => {
  return <button
    style={{height: `${height}px`}}
    type='button'
    className={styles.secondaryButton}
    onClick={onClick}
    disabled={disabled}
  >
    {text}
  </button>
};

export default SecondaryButton;
