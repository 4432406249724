import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import '../../sass/components/common/Timer.scss';

class CountdownTimer extends React.PureComponent {

  renderComplete = () => {
    const { event } = this.props;
      if (event) {
        document.getElementById('refreshLink').click();
      }
    return null;
  }

  renderTimer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return this.renderComplete();
    } else {
      return (
        <>
          {this.props.withoutTitle ? null : <div className='title'>The next game starts in</div>}
          <div className={`${!this.props.withoutStyle && 'time'}`}>
            {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
          </div>
        </>
      )
    }
  }

  render = () => {
    const { event } = this.props;
    return (
      <div className='countdownTimer'>
        {event ? <a id='refreshLink' href={`/events/${event.id}`} /> : null}
        <Countdown date={this.props.date * 1000} renderer={this.renderTimer} />
      </div>
    )
  }

}

export default CountdownTimer;
