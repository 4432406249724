import React from 'react';
import classnames from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';

import { eventOn, eventOff } from '../../helpers/global';

import '../../sass/components/common/ContentPopup.scss';

class ContentPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: !props.show,
      show: false,
      title: null,
      content: null,
      windowStyle: null,
      contentStyle: null,
      confirmText: null,
      cancelText: null,
      callback: null,
      switchContent: null,
      overlayBlock: null,
    };
  }

  componentDidMount = () => {
    if (this.props.global) {
      eventOn('showContentPopup', this.showContentPopup);
      eventOn('hideContentPopup', this.hideContentPopup);
    }
  }

  componentWillUnmount = () => {
    if (this.props.global) {
      eventOff('showContentPopup', this.showContentPopup);
      eventOff('hideContentPopup', this.hideContentPopup);
    }
  }

  showContentPopup = (e) => {
    const {
      title,
      content,
      windowStyle,
      contentStyle,
      confirmText,
      cancelText,
      callback,
      switchContent,
      overlayBlock
    } = e.detail[0];

    this.setState({
      show: true,
      hidden: false,
      title,
      content,
      windowStyle,
      contentStyle,
      confirmText,
      cancelText,
      callback,
      switchContent,
      overlayBlock
    });
  }

  hideContentPopup = () => {
    this.setState({show: false});
  }

  onOverlayClick = (e) => {
    if (!this.state.overlayBlock && e.target === e.currentTarget) {
      this.onClose(false);
    }
  }

  onClose = (res) => {
    if (!res) {
      this.setState({show: false});
      return null;
    }
    if (this.props.global) {
      const ret = this.state.callback && this.state.callback(res);
      if (ret && ret.constructor && ret.constructor.name === 'Promise') {
        ret.then(() => {
          this.setState({show: false});
        }).catch(() => {});
      } else {
        this.setState({show: false});
      }
    } else if (this.props.callback) {
      this.props.callback(res);
    }
  }

  render = () => {
    const {
      show,
      title,
      confirmText,
      cancelText,
      content,
      windowStyle,
      contentStyle,
      switchContent,
    } = this.props.global ? this.state : this.props;
    return (
      <div
        className={classnames({
          contentPopup: true,
          hidden: this.state.hidden
        })}
      >
        <CSSTransition
          in={show}
          timeout={350}
          classNames='fade'
          onEnter={() => this.setState({hidden: false})}
          onExited={() => this.setState({hidden: true})}
        >
          <div
            onClick={this.onOverlayClick}
            className='overlay'
          >
            <div className='popupWindow' style={windowStyle}>
              <div className={classnames('popupContent', {
                     switchContent: switchContent
                   })}
                   style={contentStyle}>
                <div className='closeButton' onClick={() => this.onClose(false)}/>
                <div className='popupTitle'>{title}</div>
                {content}
                {confirmText || cancelText ?
                  <div className='popupControls'>
                    {confirmText ?
                      <div
                        onClick={() => this.onClose(true)}
                        className='popupControl confirm'
                      >{confirmText || 'Confirm'}</div>
                    : null}
                    {cancelText ?
                      <div
                        onClick={() => this.onClose(false)}
                        className='popupControl cancel'
                      >{cancelText || 'Cancel'}</div>
                    : null}
                  </div>
                : null}
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default ContentPopup;
