import React from 'react';
import Active from './Active';
import Finished from './Finished';
import Next from './Next';

class WrapperEvent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      event: props.event || null,
    };
  }

  render = () => {
    const { event } = this.state;
    if (!event) return null;

    return event.soon
      ? <Next event={event} fetchEvent={this.props.fetchEvent} />
      : event.active
        ? <Active event={event} fetchEvent={this.props.fetchEvent} />
        : <Finished event={event} fetchEvent={this.props.fetchEvent} />;
  }
}
export default WrapperEvent;
