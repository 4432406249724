import React from 'react';
import { connect } from 'react-redux';
import { triggerEvent } from '../../helpers/global';
import { withRouter } from 'react-router-dom';
import PrimaryButton from '../common/Button/Primary/PrimaryButton';
import { sendRequest } from '../../helpers/RequestDispatcher';
import Timer from '../common/Timer';

import '../../sass/components/EventView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class Active extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      event: props.event || null,
      timeLeft: props.event.finished_at
    };
    this.inputRef = React.createRef();
  }

  componentDidMount = () => {
    this.countdownInterval();
  }

  componentWillUnmount = () => {
    clearInterval(this.myInterval);
  }

  countdownInterval = () => {
    this.myInterval = setInterval(() => {
      const { timeLeft } = this.state;
      this.setState({timeLeft: timeLeft - 1});
    }, 1000);
  }

  handleFileChange = (file) => {
    this.uploadImage(file)
  };

  uploadImage = (file) => {
    const { event } = this.state;
    if (!file) {
      triggerEvent('showSnackbar', [{text: 'Please upload Image', type: 'error'}]);
      return;
    }
    const formData = new FormData();

    formData.append('image', file);

    formData.append('event_id', event.id);
    sendRequest({
      type: 'POST',
      method: 'messages',
      formData,
      success: (data) => {
        this.setState({
          file: null,
          fileUrl: '',
        }, () => {
          this.props.fetchEvent();
          triggerEvent('showSnackbar', [{text: 'Photo uploaded', type: 'success'}]);
        })
      },
      error: (data) => {
        if (data.errors && data.errors.image) {
          triggerEvent('showSnackbar', [{text: data.errors.image, type: 'error'}]);
        }
      }
    });
  }

  render = () => {
    const { event } = this.state;
    const { user } = this.props;
    if (!event) return null;

    return (
      <div className='eventDescription'>
        <div className='taskTitle'>Task</div>
        <div className='task'>{event.task}</div>
        <div className='taskTitle'>Example</div>
        <div
          className='image'
          style={{backgroundImage: `url(${event.image})`}}
          onClick={() => triggerEvent('showContentPopup', [{
            content: <div
              className='modalImage'
              style={{backgroundImage: `url(${event.image})`}}
              onClick={() => window.open(event.image, '_blank')}
            />
          }])}
        />
        <div className='timeLeft'>
          <span>Duration</span>
          <Timer event={event} finished_at={event.finished_at} scheduled_at={event.scheduled_at}/>
        </div>
        <div className='btnGroup'>
          {user
           ? <>
                <input
                  type='file'
                  style={{display: 'none'}}
                  ref={this.inputRef}
                  accept='image/png,image/x-png,image/gif,image/jpeg'
                  onChange={e => this.handleFileChange(e.target.files[0])}
                />
                <PrimaryButton text='Upload your photo' onClick={() => this.inputRef.current.click()} />
              </>
            : <PrimaryButton
                text='Login before upload photo'
                onClick={() => this.props.history.push('/sign_in')}
              />
          }
        </div>
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(Active));
